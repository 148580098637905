
import Vue from "vue";
import Transaction from "@/components/Transaction.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import WithdrawFundsModal from "@/components/Modals/WithdrawFundsModal.vue";
import "vue2-datepicker/index.css";
import TransactionsLoading from "@/components/TransactionsLoading.vue";
import MentorWalletHeader from "@/components/Wallet/MentorWalletHeader.vue";
import MenteeWalletHeader from "@/components/Wallet/MenteeWalletHeader.vue";
import WalletHeaderLoading from "@/components/Wallet/WalletHeaderLoading.vue";

export default Vue.extend({
  name: "WalletPage",
  components: {
    Transaction,
    MentorWalletHeader,
    MenteeWalletHeader,
    WithdrawFundsModal,
    TransactionsLoading,
    WalletHeaderLoading
  },
  data() {
    return {
      data: [] as any,
      date: moment().format("MMMM, YYYY"),
      page: 1,
      load_more: false,
      total_balance: "0",
      pending_balance: "0",
      json_data: [] as any,
      json_excel: [] as any,
      userType: "" as string | null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      wallets: "wallet/getWallets",
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  mounted() {
    this.getWallets(1);
    this.userType = localStorage.getItem("userType");
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY").then(() => {
      this.setCurrency();
    });
  },
  methods: {
    setCurrency() {
      console.log(this.platformCurrency);
      this.total_balance += this.platformCurrency;
      this.pending_balance += this.platformCurrency;
    },
    updateDate(date: string) {
      this.date = date;
      this.getWallets(1);
    },
    numberWithCommas(number: any) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    nextOrPrevMonth(type: string) {
      const amount = type === "next" ? 1 : -1;
      this.date = moment(this.date, "MMMM, YYYY")
        .add(amount, "month")
        .format("MMMM, YYYY")
        .toString();
    },
    onLoadMore() {
      this.page++;
      this.getWallets(this.page);
    },
    getWallets(page: any) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.data = [];
      const date_page = {
        date: moment(this.date, "MMMM, YYYY")
          .format("YYYY-MM")
          .toString(),
        page
      };
      this.$store.dispatch("wallet/GET_ALL_WALLET", date_page).then(data => {
        this.page = page;
        this.load_more = page < data.paged_data.last_page;
        this.total_balance = data.total_balance;
        this.pending_balance = data.pending_balance;
        this.buildData(data);
        this.buildExcelData(data);
        this.loading = false;
      });
    },
    buildData(data: any) {
      for (const item of data.paged_data.data) {
        const excel_data = {
          id: item.id,
          subject: item.subject,
          transaction_date: item.transaction_date,
          user_id: item.user_id,
          payment_method_id: item.payment_method_id,
          total_price_paid: item.total_price_paid,
          created_at: moment(item.created_at).format("YYYY-MM-DD"),
          updated_at: moment(item.updated_at).format("YYYY-MM-DD"),
          payment_method_type: item.payment_method_type,
          payer_id: item.payer_id,
          sub_total: item.sub_total,
          paid_to: item.paid_to,
          paid_from: item.paid_from,
          mentorice_fee: item.mentorice_fee,
          donations: item.donations,
          user: item.user,
          payer: item.payer
        };

        this.data.push(item);
        this.json_data.push(excel_data);
      }
    },
    buildExcelData(data: any) {
      // for (const item of data.paged_data.data) {
      // 	const excel_data = {
      // 		id: item.id,
      // 		subject: item.subject,
      // 		transaction_date: item.transaction_date,
      // 		user_id: item.user_id,
      // 		total_received: item.total_price_paid,
      // 		payment_method_type: item.payment_method_type,
      // 		payer_id: item.payer_id,
      // 		paid_from: item.paid_from,
      // 	};
      //
      // 	this.json_excel.push(excel_data)
      // }

      for (const item of data.paged_data.data) {
        if (item.is_refunded || item.type == "withdrawal") {
          continue;
        }
        if (item.user.type === "mentor") {
          const excel_data = {
            id: item.id,
            subject: item.subject,
            transaction_date: item.transaction_date,
            user_id: item.user_id
            // total_received:
            //   item.type == "paid" ? item.total_price_paid : item.sub_total,
            // currency: "USD",
            //currency: this.platformCurrency.name,
            // payer_id: item.payer_id,
            // paid_from: item.paid_from
          };
          this.json_excel.push(excel_data);
        } else {
          const excel_data = {
            id: item.id,
            subject: item.subject,
            transaction_date: item.transaction_date,
            user_id: item.user_id
            // payment_method_type: item.payment_method_type,
            // total_price_paid:
            //   item.type == "paid" ? item.total_price_paid : item.sub_total,
            // paid_to: item.paid_to,
            // donations: item.donations
          };
          this.json_excel.push(excel_data);
        }
      }
    }
  }
});
