
import Vue from "vue";
import { Transaction } from "@/types/interfaces";
import TransactionModal from "@/components/Modals/TransactionModal.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Transaction",
  components: {
    TransactionModal
  },
  props: {
    transaction: {
      type: Object as () => Transaction
    }
  },
  data() {
    return {
      modalActive: false
    };
  },
  computed: {
    ...mapGetters({
      platformCurrency: "helper/getDefaultPlatformCurrency",
      profile: "profile/getProfile"
    })
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  mounted(): void {
    this.$root.$on("bv::modal::show", () => {
      this.modalActive = true;
    });
    this.$root.$on("bv::modal::hidden", () => {
      this.modalActive = false;
    });
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    numberWithCommas(number: any) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getTransactionSubjectConjunction(transaction: any): string {
      if (transaction.subject.includes("Library Content")) {
        if (this.profile.type === "mentor") return "by";
        else return "from";
      } else {
        return "with";
      }
    }
  }
});
